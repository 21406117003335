@tailwind base;
@tailwind components;
@tailwind utilities;

/* --------- */
/*  Preloader */
/* --------- */
#preloader { 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #ffffff;
    background-image: url(./Assets/pre.svg);
    background-repeat: no-repeat;
    background-position: center;
}
  
#preloader-none {
    opacity: 0;
}
  
#no-scroll {
    overflow: hidden;
    height: 100vh;
}

.App {
    text-align: center;
}

/* --------- */
/* Scrollbar */
/* --------- */

*::-webkit-scrollbar {
    width: 7px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 64, 255, 0.664);
    border-radius: 12px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(2, 31, 89, 0.406);
    border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
    /* background-color: #1b1a2ea9; */
    transition: all 0.3s ease-out 0s;
    box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171);
    backdrop-filter: blur(15px);
}
  
.navbar {
    position: fixed;
    transition: all 0.3s ease-out 0s;
    padding: 0.3rem 2rem;
    font-size: 1.2rem;
}
  
.navbar-toggler {
    position: relative;
    background-color: transparent;
    border-color: transparent;
}

  
.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

@media (max-width: 767px) {
    .navbar {
        padding: 1rem 2rem;
        font-size: 1.4rem;
        background-color: #ffffff;
    }
    .navbar-nav .nav-item a::after {
        display: none;
    }
}

.navbar-brand {
    color: rgb(250, 250, 250);
}
  
.logo {
    height: 1.4em;
    width: 2.5em;
}
  
.navbar-nav .nav-link {
    color: rgb(0, 0, 0);
    padding-right: 1rem;
    padding-left: 1rem;
    text-decoration: none;
}
  
.nav-link {
    padding: 0.8rem 1rem;
}

.navbar-toggler span {
    display: block !important;
    background-color: #000000 !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
}
  
.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
}
  
.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
}
  
.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
}
  
.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
}
  
.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
}

@media (max-width: 767px) {
    .nav-link {
      padding: 0.7rem 1rem;
    }
}
  
.navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
}
  
.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
}
  
.navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #000000;
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}
  
.navbar-nav .nav-item a:hover::after {
    width: 100%;
    text-decoration: none !important;
}

.insta-btn {
    font-size: 1.1em;
    padding-top: 4px;
}

.insta-btn-inner {
    line-height: 1.4em;
    padding: 0.13rem 2rem !important;
    vertical-align: middle;
    text-align: center;
}
  
.insta-btn-inner:hover {
    transform: translateY(-3px) !important;
    background-color: #000000eb;
    border-color: #00000086;
}

.insta-btn-inner::after {
    display: none;
}

.profile-btn {
    font-size: 1.1em;
    padding-top: 4px;
}

.profile-btn-inner {
    line-height: 1.4em;
    padding: 0.25rem 1.6rem !important;
    vertical-align: middle;
    text-align: center;
}
  
.profile-btn-inner:hover {
    transform: translateY(-1px) !important;
    background-color: #000000eb;
    border-color: #00000086;
}

.profile-btn-inner::after {
    display: none;
}

/* --------- */
/* Home Section  */
/* --------- */

section {
    height: 100%
}

section .selling-points{
    height: 200%
}

.home-header {
    background-image: url(./Assets/BobaLarge.webp);
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.friend-pic {
    background-image: url(./Assets/friend.jpeg);
    background-position: center;
    background-size: cover;
    height: 89.18vh;
}

.four-group-img .row1{
    margin-top: 2.1em;
    margin-bottom: 1em;
    height: 35vh;
    width: 50vh;
}

.four-group-img .coin{
    transform: translateX(1.7em) !important;
}

.four-group-img .row2{
    margin-top: 2.5em;
    margin-bottom: 1em;
    height: 30vh;
    width: 40vh;
}